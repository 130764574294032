<script setup>
import { animate } from 'motion'

const props = defineProps({
    number: {
        type: [String, Number],
        default: '',
    },
    start: {
        type: [String, Number],
        default: 0,
    },
})
const enter = (el) => {
    if (props.number && !Number.isNaN(props.number) && !Number.isNaN(props.start)) {
        const element = el
        const end = parseInt(props.number, 10)
        const start = parseInt(props.start, 10)
        let lastProgress = start
        const distance = end - start
        el.classList.add('will-change-contents')
        const anim = animate((progress) => {
            const progressNumber = Math.ceil(start + (distance * progress))
            if (lastProgress !== progressNumber) {
                lastProgress = progressNumber
                element.innerText = lastProgress
            }
        }, { duration: 1.2, easing: [0.14, 0.47, 0.45, 0.94], allowWebkitAcceleration: true })

        anim.finished.then(() => {
            el.classList.remove('will-change-contents')
        })
    }
}
</script>

<template>
    <VisibleProgress
        class="will-change-contents"
        v-on:enter="enter"
    >
        <slot v-html="number" />
    </VisibleProgress>
</template>
